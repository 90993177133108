/**
 * Blog Routing File
 */
import React from "react";
import { Route, Switch } from "react-router-dom";
import { UsersComponent, CreateUsersComponent } from "routes";

const Users = ({ match }) => (
  <Switch>
    <Route
      path={`${match.url}/users-details`}
      component={CreateUsersComponent}
    ></Route>
    <Route path={`${match.url}/`} component={UsersComponent}></Route>
  </Switch>
);
export default Users;
