/**
 * Customer Routing File
 */
import React from "react";
import { Route, Switch } from "react-router-dom";
import {
  CustomerDetailsComponent,
  CreateCustomerComponent,
  CustomerComponent,
  ExportCustomerComponent
} from "routes";

const Customer = ({ match }) => (
  <Switch>
    <Route
      path={`${match.url}/details`}
      component={CustomerDetailsComponent}
    ></Route>
    <Route
      path={`${match.url}/update`}
      component={CreateCustomerComponent}
    ></Route>

    <Route path={`${match.url}/List`} component={CustomerComponent}></Route>

    <Route
      path={`${match.url}/export`}
      component={ExportCustomerComponent}
    ></Route>
  </Switch>
);
export default Customer;
