/**
 * App Config File
*/

const AppConfig = {
	brandName: 'Hulk',
	copyRightText: 'Copyright © 2021 IRON Network, All Rights Reserved.',
	navCollapsed: true,
	mobileSidebarToggle: false,
	isDarkModeActive: false,
	isRtlActive: false,
	isHorizontalMenuActive: false,
	isMiniSidebarActive: false,
	selectedThemeColor: 'light-theme',
	locale: {
		languageId: 'deutsch',
		locale: 'de',
		name: 'Deutsch',
		icon: 'germany.png',
	},
	// Default Theme Colors 
	lightThemeColors: {
		'primary': '#00AD62',
		'secondary': '#ff8600',
		'success': '#00d41a',
		'info': '#00d0bd',
		'warning': '#ffb70f',
		'error': '#e53935',
		'textPrimary': '#121230',
		'textSecondary': '#717275',
		'divider': '#e0e0e0',
		'icon': '#717275',
		'bgPaper': '#FFFFFF',
		'bgDefault': '#f3f7fa',
		'white': '#ffffff',
		'black': '#000000'
	},
	// Teal Theme Colors
	tealThemeColors: {
		'primary': '#028484',
		'secondary': '#d99830',
		'success': '#00d41a',
		'info': '#00d0bd',
		'warning': '#ffb70f',
		'error': '#e64c3d ',
		'textPrimary': '#121230',
		'textSecondary': '#717275',
		'divider': '#e0e0e0',
		'icon': '#717275',
		'bgPaper': '#FFFFFF',
		'bgDefault': '#f3f7fa',
		'white': '#ffffff',
		'black': '#000000'
	},
	// Violet Theme Colors
	violetThemeColors: {
		'primary': '#53419A',
		'secondary': '#F0bc02',
		'success': '#ff8600',
		'info': '#00d0bd',
		'warning': '#ffb70f',
		'error': '#e53935',
		'textPrimary': '#121230',
		'textSecondary': '#717275',
		'divider': '#e0e0e0',
		'icon': '#717275',
		'bgPaper': '#FFFFFF',
		'bgDefault': '#f3f7fa',
		'white': '#ffffff',
		'black': '#000000'
	},
	// Dark Theme Colors
	darkThemeColors: {
		'primary': '#00AD62',
		'secondary': '#ff8600',
		'success': '#00d41a',
		'info': '#00d0bd',
		'warning': '#ffb70f',
		'error': '#e53935',
		'textPrimary': '#9d9daf',
		'textSecondary': '#717275',
		'divider': 'rgba(255,255,255,0.12)',
		'icon': '#717275',
		'bgPaper': '#27293c',
		'bgDefault': '#1e1e2e',
		'white': '#ffffff',
		'black': '#000000'
	}
}

export default AppConfig;