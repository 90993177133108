/**
 * Code splitting Components
 * AsyncComponents
*/
import React from 'react';
import Loadable from 'react-loadable';
import { HulkPageLoader } from 'components/GlobalComponents';


//  Dashboard 1
const AsyncDashboard1Component = Loadable({
	loader: () => import("routes/Dashboard/Dashboard1"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

// User Settings
const AsyncUserSettingsComponent = Loadable({
	loader: () => import("routes/UserSettings"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

// Error Page 404
const AsyncErrorPage404Component = Loadable({
	loader: () => import("routes/Error/404"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});
// Error Page 500
const AsyncErrorPage500Component = Loadable({
	loader: () => import("routes/Error/500"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});


// crm Settings
const AsyncCrmSettingsComponent = Loadable({
	loader: () => import('routes/CrmSettings'),
	loading: () => <HulkPageLoader />,
	delay: 3000,
})
// categories
const CategoriesComponent = Loadable({
	loader: () => import('routes/Categories/category'),
	loading: () => <HulkPageLoader />,
	delay: 3000,
})
// create category 
const CreateCategoryComponent = Loadable({
	loader: () => import('routes/Categories/create'),
	loading: () => <HulkPageLoader />,
	delay: 3000,
})
// products
const ProductsComponent = Loadable({
	loader: () => import('routes/Products/Products'),
	loading: () => <HulkPageLoader />,
	delay: 3000,
})
// create product 
const CreateProductComponent = Loadable({
	loader: () => import('routes/Products/productDetails'),
	loading: () => <HulkPageLoader />,
	delay: 3000,
})

// users
const UsersComponent = Loadable({
	loader: () => import('routes/Users/UsersList'),
	loading: () => <HulkPageLoader />,
	delay: 3000,
})
// create users 
const CreateUsersComponent = Loadable({
	loader: () => import('routes/Users/UsersDetails'),
	loading: () => <HulkPageLoader />,
	delay: 3000,
})

// customer
const CustomerComponent = Loadable({
	loader: () => import('routes/Customers/Customers'),
	loading: () => <HulkPageLoader />,
	delay: 3000,
})

// customer details
const CustomerDetailsComponent = Loadable({
	loader: () => import("routes/Customers/Details"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
})
 
// create customer
 const CreateCustomerComponent = Loadable({
 	loader: () => import('routes/Customers/update'),
 	loading: () => <HulkPageLoader />,
 	delay: 3000,
 })

 const ExportCustomerComponent = Loadable({
	loader: () => import("routes/Customers/Export"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
})

 // order
const OrderComponent = Loadable({
	loader: () => import('routes/Orders/Orders'),
	loading: () => <HulkPageLoader />,
	delay: 3000,
})
 // create order
 const CreateOrderComponent = Loadable({
 	loader: () => import('routes/Orders/orderDetails'),
 	loading: () => <HulkPageLoader />,
 	delay: 3000,
 })

 // export order component
 const OrderExportComponent = Loadable({
	loader: () => import('routes/Orders/Export'),
	loading: () => <HulkPageLoader />,
	delay: 3000,
})



export {
	AsyncDashboard1Component,
	AsyncUserSettingsComponent,
	CategoriesComponent,
	CreateCategoryComponent,
	CreateProductComponent,
	ProductsComponent,
	CustomerComponent ,
	CreateCustomerComponent,
	OrderComponent ,
	CreateOrderComponent,
	AsyncErrorPage404Component,
	AsyncErrorPage500Component,
	AsyncCrmSettingsComponent,
	CreateUsersComponent,
	UsersComponent,
	CustomerDetailsComponent,
	ExportCustomerComponent,
	OrderExportComponent

};