/**
 * Router service file
 */
import Dashboard from "routes/Dashboard";

// Async component
import {
   AsyncUserSettingsComponent,
   AsyncCrmSettingsComponent
} from 'routes';
import Category from 'routes/Categories';
import Products from 'routes/Products';
import Customers from 'routes/Customers';
import Users from 'routes/Users'
import Orders from 'routes/Orders';
export default [
   {
      path: 'dashboard',
      component: Dashboard
   },
   {
      path: 'user-settings',
      component: AsyncUserSettingsComponent
   },
   {
      path: 'settings',
      component: AsyncCrmSettingsComponent
   },
   {
      path: 'categories',
      component: Category
   },
   {
      path: 'products',
      component: Products
   },   
   {
      path: 'customers',
      component: Customers
   },   
   {
      path: 'orders',
      component: Orders
   },
   {
      path: "users",
      component: Users,
    },
  

]
