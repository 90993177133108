/**
 * Blog Routing File
 */
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import {
  CreateCategoryComponent,
  CategoriesComponent
} from 'routes'

const Customers = ({ match }) => (
  <Switch>
 
     <Route
      path={`${match.url}/create`}
      component={CreateCategoryComponent}
    ></Route>
   
    <Route
      path={`${match.url}/`}
      component={CategoriesComponent}
    ></Route>
    
    
   
  </Switch>
)
export default Customers
