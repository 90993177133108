export const statisticService = {
   getDailyStatistic,
   getCustomerMonthlyStatistic,
   getOrderMonthlyStatistic,
  amountMonthlyStatistic
  
};


// get daily statistic
function getDailyStatistic() {
   const requestOptions = {
      method: 'GET'
   };

   return fetch(`${process.env.REACT_APP_API_URL}/dailyStatistic`, requestOptions).then(handleResponse);
}



// get customer monthly statistic
function getCustomerMonthlyStatistic(dateObject) {
   const requestOptions = {
      method: 'POST',
      body: dateObject,
      headers: {
         'Content-type': 'application/json',
      },
   };

   return fetch(`${process.env.REACT_APP_API_URL}/customerMonthlyStatistic`, requestOptions).then(handleResponse);
}


// get order monthly statistic
function getOrderMonthlyStatistic(dateObject) {
   const requestOptions = {
      method: 'POST',
      body: dateObject,
      headers: {
         'Content-type': 'application/json',
      },
   };

   return fetch(`${process.env.REACT_APP_API_URL}/orderMonthlyStatistic`, requestOptions).then(handleResponse);
}

// get order monthly statistic
function amountMonthlyStatistic(dateObject) {
   const requestOptions = {
      method: 'POST',
      body: dateObject,
      headers: {
         'Content-type': 'application/json',
      },
   };

   return fetch(`${process.env.REACT_APP_API_URL}/amountMonthlyStatistic`, requestOptions).then(handleResponse);
}
// handle response
function handleResponse(response) {
   return response.text().then(text => {
      const data = text && JSON.parse(text);
      if (!response.ok) {
         const error = (data && data.message) || response.statusText;
         return Promise.reject(error);
      }
      return data;
   });
}