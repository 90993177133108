export const productCategoryService = {
    getAll,
    getById,
    create,
    update,
    remove,
  };
  
  function getAll() {
    const requestOptions = {
      method: 'GET',
    };
  
    return fetch(`${process.env.REACT_APP_API_URL}/products/category/getAll`, requestOptions).then(handleResponse);
  }
  
  function getById(id) {
    const requestOptions = {
      method: 'GET',
    };
  
    return fetch(`${process.env.REACT_APP_API_URL}/products/category/${id}`, requestOptions).then(handleResponse);
  }
  
  function create(productCategory) {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body:productCategory,
    };
  
    return fetch(`${process.env.REACT_APP_API_URL}/products/category/create`, requestOptions).then(handleResponse);
  }
  
  function update(id, productCategory) {
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: productCategory,
    };
  
    return fetch(`${process.env.REACT_APP_API_URL}/products/category/update/${id}`, requestOptions).then(handleResponse);
  }
  
  function remove(id) {
    const requestOptions = {
      method: 'DELETE',
    };
  
    return fetch(`${process.env.REACT_APP_API_URL}/products/category/delete/${id}`, requestOptions).then(handleResponse);
  }
  
// handle response
function handleResponse(response) {
    return response.text().then(text => {
       const data = text && JSON.parse(text);
       if (!response.ok) {
          const error = (data && data.message) || response.statusText;
          return Promise.reject(error);
       }
       return data;
    });
 }