/**
 * Order Routing File
 */
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import {
  CreateOrderComponent,
  OrderComponent,
  OrderExportComponent
} from 'routes'

const Order = ({ match }) => (
  <Switch>
 <Route
      path={`${match.url}/order-details`}
      component={CreateOrderComponent}
    ></Route> 
    
    <Route
      path={`${match.url}/list`}
      component={OrderComponent}
    ></Route> 
    <Route
            path={`${match.url}/export`}
            component={OrderExportComponent}
        ></Route>
  </Switch>
)
export default Order
