

export const crmService = {
   get,
   update,
   create
};


// get crm settings
function get() {
   const requestOptions = {
      method: 'GET'
   };

   return fetch(`${process.env.REACT_APP_API_URL}/crm/get`, requestOptions).then(handleResponse);
}

// edit crm
function update(crm_general_id, object) {
   const requestOptions = {
      method: 'PUT',
      body: object
   };
   return fetch(`${process.env.REACT_APP_API_URL}/crm/update/${crm_general_id}`, requestOptions).then(handleResponse);
}

function create( object) {
   const requestOptions = {
      method: 'post',
      
      body: object
   };
   return fetch(`${process.env.REACT_APP_API_URL}/crm/create`, requestOptions).then(handleResponse);
}
// handle response
function handleResponse(response) {
   return response.text().then(text => {
      if (response.ok) {
         const data = text && JSON.parse(text);
         return data;
      }

   }).catch(error => { console.log(error) })
      ;
}
