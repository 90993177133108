/**
 * Forget Password
*/
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { TextField, Button, Box, Typography, Divider } from "@material-ui/core";
import { CustomCard } from 'components/GlobalComponents';
import { Link } from 'react-router-dom';
import { userService } from '../../_services'
import IntlMessages from 'util/IntlMessages';
const urlParams = new URLSearchParams(window.location.search)
class ResetPasswordFirebase extends Component {
	constructor(props) {
		super(props);
		this.state = {
			token: urlParams.get('token'),
			user_id: urlParams.get('id'),
			errors: {
				password: '',
				passwordMatch: ''
			}

		}
	}


	onSubmit(e) {
		e.preventDefault()
		let isValid = this.formValidation()
		if (isValid) {
			this.resetPassword()
		}
	}

	resetPassword() {
		let object = {
			user_id: this.state.user_id,
			token: this.state.token,
			password: this.state.password
		}
		userService.resetPassword(object).then(result => {
			this.props.history.push('/signin')
		})
	}
	formValidation() {
		const passwordErrors = {}
		let isValid = true
		if (!this.state.password || this.state.password.length < 6 || !this.state.passwordConfirm) {
			passwordErrors.password = 'component.passwordError'
			isValid = false
		} else if (this.state.password !== this.state.passwordConfirm) {
			passwordErrors.passwordMatch = 'component.passwordMatchError'
			isValid = false
		}
		this.setState({ errors: passwordErrors })
		return isValid
	}

	handleChange(name, event) {
		this.setState({ [name]: event.target.value });
	};

	render() {
		return (
			<div>
				<div className="session-wrapper session-wrapper-v2">
					<Box className="session-box" mx="auto" display="flex" justifyContent="center" alignItems="center">
						<Box width="100%">
							<Box textAlign="center" className="session-logo" >
								
							</Box>
							<CustomCard>
								<form className="login-form text-center">
									<Typography variant="subtitle2" color="textPrimary" className="title">PASSWORT VERGESSEN</Typography>
									<Box my={2}>
										<FormattedMessage id="component.newPassword">
											{placeholder =>
												<TextField
													required
													fullWidth
													placeholder={placeholder}
													className="outlined-input"
													variant="outlined"
													onChange={(event) => this.handleChange('password', event)}
													id="new-password"
													type="password"
													autoComplete="current-password"
												/>}</FormattedMessage>
									</Box>
									{this.state.errors.password &&
										<div className="text-danger"><IntlMessages id={this.state.errors?.password} /></div>
									}
									<Box my={2}>
										<FormattedMessage id="component.retypePassword">
											{placeholder =>
												<TextField
													required
													fullWidth
													placeholder={placeholder}
													variant="outlined"
													className="outlined-input"
													type="password"
													autoComplete="current-password"
													onChange={(event) => this.handleChange('passwordConfirm', event)}
													id="retype-password"
												/>}</FormattedMessage>
									</Box>
									{this.state.errors.passwordMatch &&
										<div className="text-danger"><IntlMessages id={this.state.errors?.passwordMatch} /></div>

									}
									<Box py={3}>
										<Button
											color="primary"
											className="btn-block blockBtn w-100"
											variant="contained"
											size="large"
											onClick={(event) => this.onSubmit(event)}
										>
											Anmelden
										</Button>
									</Box>
									<Divider></Divider>
									<Box pt={2} fontSize="subtitle2.fontSize">
										<Box style={{ cursor: 'pointer' }} color="primary.main" component={Link} to="/signin" >Zurück zur Anmeldung</Box>

									</Box>
								</form>
							</CustomCard>
						</Box>
					</Box>
				</div>
			</div>
		);
	}
}


export default (ResetPasswordFirebase);